@import "src/mixins.scss"; //ok
@import 'src/standard-mixins.scss';

/* ADDICTION FILTER MENU */
addiction-filter-menu {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  padding: 20px;
  overflow-y: auto;
  @include scroll-bar;

  > .buttons-container {
    @include flex-center;

    button {
      @include button-style(36px, $white, $white, $buttonIcon: false, $buttonBackground: false);
      width: 100%;
      padding: 5px;
      margin-bottom: 20px;
      letter-spacing: 1.5px;
    }

  }

  .checkbox-filters-container {
    display: flex;
    flex-flow: column;

    .single-filter {
      display: flex;
      flex-flow: column;
      margin-bottom: 20px;

      .filter-title {
        @include font-style(10px, $font-medium, transparentize($white, 0.5), 0.5px, 150%);
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      .filter-options {
        display: flex;
        flex-flow: column;
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }

        .single-filter-option {
          margin-bottom: 10px;

          &:last-of-type {
            margin-bottom: 0;
          }

          mat-checkbox {
            @include mat-checkbox-style(transparentize($white, 0.9), $primary, none);
            width: 100%;

            .mdc-form-field {
              width: 100%;

              .mdc-label {
                text-overflow: ellipsis;
                overflow: hidden;
              }

            }

          }

        }

        .buttons-container {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          button {
            appearance: none;
            border: none;
            outline: none;
            padding: 0;
            background-color: $transparent;
            @include font-style(10px, $font-medium, $brand-color-14, 1px, 150%);
            cursor: pointer;
            text-transform: uppercase;

            @media (hover: hover) and (pointer: fine) {

              &:hover {
                opacity: 0.5;
              }

            }

          }

        }

      }

      .single-filter-option.tag {

        form {
          display: flex;
          flex-flow: column;

          .chip-container {
            display: flex;
            flex-flow: column;

            mat-chip-grid {
              margin-top: 5px;

              .mdc-evolution-chip-set__chips {

                .single-chip {

                  mat-chip-row {
                    min-height: 32px;
                    max-height: unset;
                    height: auto;
                    padding: 10px 0;
                    border-radius: 0;
                    background: $primary;

                    @media (hover: hover) and (pointer: fine) {

                      &:hover {

                        .mdc-evolution-chip__cell {

                          .remove {
                            opacity: 0.5;
                          }

                        }

                      }

                    }

                    .mat-ripple {
                      display: none;
                    }

                    .mat-mdc-chip-focus-overlay {
                      display: none;
                    }

                    .mdc-evolution-chip__action {

                      .mdc-evolution-chip__text-label {

                        .text {
                          @include font-style(10px, $font-medium, $white, 0, 150%);
                          white-space: break-spaces;
                        }

                      }

                    }

                    .mdc-evolution-chip__cell {

                      .remove {
                        position: relative;
                        display: flex;
                        opacity: 1;

                        &::before {
                          content: "d";
                          width: 10px;
                          height: 10px;
                          font-size: 10px;
                          color: $white;
                          font-family: $font-icon;
                          position: absolute;
                          top: unset;
                          right: unset;
                          bottom: unset;
                          left: unset;
                          @include flex-center;
                          margin: 0;
                          border: none;
                          pointer-events: none;
                        }

                      }

                    }

                  }

                }

              }

            }

            .mat-mdc-chip-input {
              width: 100%;
              height: 36px;
              flex: unset;
              padding: 5px 10px;
              margin: 0;
              caret-color: $white;
              border: none;
              box-sizing: border-box;
              background: transparentize($white, 0.95);
              border-radius: 5px;
              @include font-style(14px, $font-regular, $white, 0, 100%);
              cursor: inherit;
            }

            mat-autocomplete {}

          }

        }

      }

      .single-filter-option.category {

        form {
          display: flex;
          flex-flow: column;

          addiction-autocomplete {}

        }

      }

    }

  }

  .dates-filters-container {
    display: flex;
    flex-flow: column;

    .single-filter {
      display: flex;
      flex-flow: column;
      margin-bottom: 20px;

      .filter-title {
        @include font-style(10px, $font-medium, transparentize($white, 0.5), 0.5px, 150%);
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      addiction-date-picker {}

    }

  }

}
