@import 'src/mixins.scss'; //ok
@import 'src/standard-mixins.scss';

addiction-html-editor {
  width: 100%;
  max-width: 100%;

	form {
		width: 100%;
		display: flex;
		flex-flow: column;

		ngx-editor-menu {
			z-index: 10;

      &[ng-reflect-disabled="true"] {
        cursor: not-allowed;

        .NgxEditor__MenuBar {
          pointer-events: none;
        }

      }

      &[ng-reflect-disabled="true"] + ngx-editor {
        opacity: 0.5;
        pointer-events: none;
      }

			.NgxEditor__MenuBar {
        height: auto;
				min-height: 50px;
				padding: 12px 10px;
				margin-top: 5px;
				background: #2C3446;
				display: flex;
				justify-content: flex-start;
				align-items: center;
        align-content: center;
        column-gap: 5px;
        row-gap: 10px;
				border-radius: 5px;

        .NgxEditor__Seperator {
          display: none;
        }

				ngx-toggle-command {
					cursor: pointer;

					@media (hover: hover) and (pointer: fine) {

						&:hover {
							background: $transparent;
						}

					}

					.NgxEditor__MenuItem--Active {
						background: transparentize($white, 0.85);
						border-radius: 5px;
					}

				}

				ngx-dropdown {
					cursor: pointer;

					@media (hover: hover) and (pointer: fine) {

						&:hover {
              border-radius: 5px;
              background: transparentize($white, 0.85);
						}

					}

					&.NgxEditor__Dropdown {
						height: 30px;
            cursor: pointer;

						.NgxEditor__Dropdown--Text {
							font-size: 14px;
							padding: 0 10px;
              cursor: pointer;

							&.NgxEditor__Dropdown--Selected {
								background: $transparent;
								color: $white;
							}

							&::after {
								margin-left: 10px;
							}

						}

					}

					.NgxEditor__Dropdown--DropdownMenu {
						background: $brand-color-10;
						top: 34px;

						.NgxEditor__Dropdown--Item {
							font-size: 14px;
							padding: 10px;

							&.NgxEditor__Dropdown--Active {
								background: transparentize($white, 0.5);
								color: $brand-color-10;
							}

							@media (hover: hover) and (pointer: fine) {

								&:hover {
									color: $brand-color-10;
								}

							}

						}

					}

				}

				ngx-link {
					cursor: pointer;

					@media (hover: hover) and (pointer: fine) {

						&:hover {
							background-color: $transparent;
						}

					}

					.NgxEditor__MenuItem--Active {
						background: transparentize($white, 0.85);
						border-radius: 5px;
					}

					.NgxEditor__Popup {
						top: 34px;
						background: $brand-color-10;

						.NgxEditor__Popup--Form {

							.NgxEditor__Popup--FormGroup {

								.NgxEditor__Popup--Col {

									label {
										color: $white;
										font-size: 12px;
										display: flex;
										align-items: center;

										> input {
											//campo checkbox
											@include input-checkbox;
											margin-right: 5px;
										}

									}

									> input {
										//campi testo
										width: 100%;
										height: 30px;
										border: none;
										border-radius: 5px;
										background: transparentize($white, 0.85);
										font-size: 14px;
										padding: 0 5px;
										margin-bottom: 5px;
									}

									.NgxEditor__HelpText {
										font-size: 12px;
									}

								}

							}

							> button {
								color: $white;
								background: transparentize($white, 0.85);
								border-radius: 5px;
								padding: 5px 10px;
								border: none;
								box-shadow: none;
								margin-top: 10px;
								appearance: none;
								cursor: pointer;
								transition: $standard-transition;

								@media (hover: hover) and (pointer: fine) {

									&:hover {
										opacity: 0.5;
									}

								}

							}

						}

					}

				}

				ngx-color-picker {
					cursor: pointer;

					@media (hover: hover) and (pointer: fine) {

						&:hover {
							background-color: $transparent;
						}

					}

					.NgxEditor__MenuItem--Active {
						background: transparentize($white, 0.85);
						border-radius: 5px;
					}

					.NgxEditor__Popup {
						top: 34px;
						background: $brand-color-10;

						.NgxEditor__ColorContainer {

							button {
								border: 1px solid $white;
								margin: 0;
								padding: 0;
								cursor: pointer;
								transition: $standard-transition;

								@media (hover: hover) and (pointer: fine) {

									&:hover {
										border: 1px solid transparentize($white, 0.85);
									}

								}

							}

						}

						.NgxEditor__MenuItem--Button {
							color: $white;
							background: transparentize($white, 0.85);
							border-radius: 5px;
							padding: 5px 10px;
							border: none;
							box-shadow: none;
							margin-top: 10px;
							appearance: none;
							cursor: pointer;
							transition: $standard-transition;

							@media (hover: hover) and (pointer: fine) {

								&:hover {
									opacity: 0.5;
								}

							}

						}

					}

				}

        .NgxEditor__MenuItem--Icon {
          cursor: pointer;

          @media (hover: hover) and (pointer: fine) {

            &:hover {
              border-radius: 5px;
              background: transparentize($white, 0.85);
            }

          }

        }

			}

		}

		ngx-editor {
			z-index: 0;

			&[aria-disabled='true'],
			&[aria-readonly='true'] {
				cursor: not-allowed;
				opacity: 0.5;
			}

			.NgxEditor {
				padding: 0;
				margin: -5px -1px 0 -1px;
				background: $brand-color-12;
				border-radius: 0 0 5px 5px;

				.NgxEditor__Content {
					max-height: 180px;
					margin-top: 20px;
					padding: 0 10px 10px 10px;
					@include font-style(14px, $font-regular, $white, 0, 160%);
					cursor: text;
					overflow-y: auto;
					@include scroll-bar;

					a {
						color: $brand-color-04;
					}

					b,
					strong {
						font-weight: $font-bold;
					}

					em {
						font-style: italic;
					}

					u {
						text-decoration: underline;
					}

					s {
						text-decoration: line-through;
					}

					h1 {
						@include font-style(33px, $font-bold, $white, 1px, 100%);
						cursor: text;
						margin: 20px 0;
					}

					h2 {
						@include font-style(30px, $font-bold, $white, 1px, 110%);
						cursor: text;
						margin: 20px 0;
					}

					h3 {
						@include font-style(27px, $font-bold, $white, 1px, 120%);
						cursor: text;
						margin: 20px 0;
					}

					h4 {
						@include font-style(24px, $font-bold, $white, 1px, 130%);
						cursor: text;
						margin: 20px 0;
					}

					h5 {
						@include font-style(21px, $font-bold, $white, 1px, 140%);
						cursor: text;
						margin: 20px 0;
					}

					h6 {
						@include font-style(18px, $font-bold, $white, 1px, 150%);
						cursor: text;
						margin: 20px 0;
					}

				}

			}

		}

	}

}
