@import 'src/standard-mixins.scss';

/* ADDICTION INPUT */
addiction-input {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;

	.invalid-input {
		border: 1px solid red !important;
	}

	.invalid-input-message {
 		color: red;
    @include font-style(12px, $font-regular, red, 2.5px, 100%);
	}

	.form-field {
		width: 100%;

		&.disabled,
		&.readonly {

			.form-input-container {
				&.number {
					&::before,
					&::after {
						opacity: 0.5;
					}
				}
			}

		}

		label {
			width: 100%;
			display: flex;
			@include font-style(10px, $font-medium, transparentize($white, 0.5), 2.5px, 100%);
			text-transform: uppercase;
			margin-bottom: 10px;
			@include tooltip($white, 13px);
		}

		.form-input-container {
			width: 100%;
			display: flex;
			align-items: center;
			position: relative;

			&.number {

				&::before {
					content: 'b';
					width: 16px;
					height: 15px;
					color: $white;
					font-size: 7px;
					font-family: $font-icon;
					background: $brand-color-12;
					position: absolute;
					right: 10px;
					top: 5px;
					cursor: pointer;
					transition: $standard-transition;
					@include flex-center;
					transform: scaleY(-1);
					pointer-events: none;
					z-index: 1;
				}

				&::after {
					content: 'b';
					width: 16px;
					height: 15px;
					color: $white;
					font-size: 7px;
					font-family: $font-icon;
					background: $brand-color-12;
					position: absolute;
					right: 10px;
					bottom: 5px;
					cursor: pointer;
					transition: $standard-transition;
					@include flex-center;
					pointer-events: none;
					z-index: 1;
				}

			}

			input {
				width: 100%;
				height: 36px;
				@include font-style(14px, $font-regular, $white, 0, 100%);
				cursor: text;
				padding: 5px 10px;
				background: transparentize($white, 0.95);
				border: none;
				margin: 0;
				border-radius: 5px;

				&::placeholder {
					@include font-style(14px, $font-regular, transparentize($white, 0.5), 0, 100%);
				}

				&:disabled,
				&:read-only {
					cursor: not-allowed;
					opacity: 0.5;
				}

				&[type='number'] {

					&::-webkit-textfield-decoration-container,
					&::-webkit-inner-spin-button {
						cursor: pointer;
					}

				}

			}

			mat-icon.append-icon {
				@include flex-center;
				font-size: 16px;
				position: absolute;
				right: 5px;
				cursor: pointer;

				@media (hover: hover) and (pointer: fine) {
					&:hover {
						opacity: 0.5;
					}
				}

			}

		}

	}

}
