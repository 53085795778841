@import "variables.scss";
@import "standard-mixins.scss";

@mixin form-fields-container {
  display: flex;
  align-items: flex-end;
  flex-flow: row wrap;
  row-gap: 20px;

  .divider {
    width: calc(100% - 20px);
    height: 1px;
    margin: 20px 10px 0 10px;
    background: transparentize($brand-color-08, 0.8);
  }

  .title-fields-container {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: 0 10px;
    margin-top: 30px;

    &.no-space {
      padding: 0;
      margin: 0;
    }

    h2 {
      width: 100%;
      @include font-style(20px, $font-light, $white, 0, 100%);
      margin: 0 !important;
    }

  }

  .datalean-container { /* Per ora solo feature-editor.component.html */
    width: 100%;
  }

  .fields-container {
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-flow: row wrap;
    row-gap: 20px;

    &.radio-dates-container {
      width: 50%;

      @media screen and (max-width: 1900px) {
        width: 100%;
      }

    }

    .skeleton {
      display: flex;
      align-items: center;
      column-gap: 20px;
      width: 100%;
      height: 36px;
      cursor: pointer;
      @include skeleton-loading;
    }

  }

  .non-repeatable-container {
    width: 100%;
  }

  //.cdk-drop-list.repeatable-container {
  .repeatable-container {
    width: calc(100% - 20px);
    margin: 0 10px;
    display: flex;
    flex-flow: column;

    > label {
      width: 100%;
      @include font-style(20px, $font-light, $white, 0, 100%);
      margin-bottom: 20px;
      @include tooltip($white, 15px);
    }

    .drag-placeholder {
      width: 100%;
      height: 20px;
      background: $brand-color-04;
    }

    .single-element {
      width: 100%;
      height: auto;
      border: 1px solid $brand-color-12;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      border-radius: 5px;
      transition: all 0s $bezier;

      &.collapsed {
        background: $brand-color-12;
      }

      &.closed {
        background: $brand-color-12;

        .repeatable-fields-container {
          height: 0;
          opacity: 0;
          padding: 0 10px;
          overflow: hidden;
          background: $brand-color-12;
        }

        .buttons-container {
          padding: 0;
        }

      }

      .drag-container {
        width: 40px;
        border-radius: 5px;
        background: $brand-color-12;
        display: flex;
        flex-flow: column;
        @include flex-center;
        cursor: grab;
        transition: $standard-transition;

        @media (hover: hover) and (pointer: fine) {

          &:hover {
            background: transparentize($brand-color-04, 0.5);
          }

        }

        &.disabled {
          pointer-events: none;
          cursor: default;

          .number {
            display: none;
          }

        }

        .number {
          @include font-style(10px, $font-medium, $white, 1.5px, 100%);
          text-align: center;
          cursor: grab;
        }

      }

      .repeatable-fields-container {
        width: calc(100% - 80px);
        display: flex;
        align-items: center;
        padding: 20px 10px;
        transition: $standard-transition;

        &.collapsed {
          height: 0;
          overflow: hidden;

          & > * {
            display: none;
          }

        }

        &.expanded {
          height: auto;
          overflow: visible;
        }

        &.no-drag,
        &.no-buttons {
          width: calc(100% - 40px);
        }

        &.no-drag.no-buttons {
          width: 100%;
        }

        datalean-container { }

      }

      .buttons-container {
        width: 40px;
        padding: 10px 0;
        border-radius: 5px;
        background: $brand-color-12;
        @include flex-center;
        flex-flow: column;
        transition: $standard-transition;

        button.clone {
          @include button-style("k", $white, $white, $buttonIcon: true, $buttonBackground: false);
          border: none;
        }

        button.accordion {
          @include button-style("a", $white, $white, $buttonIcon: true, $buttonBackground: false);
          border: none;

          &::before {
            font-size: 7px;
            @include flex-center;
            margin-left: 2px;
            transform: rotate(90deg);
          }

          &::after {
            content: "";
            width: 16px;
            height: 16px;
            border: 1px solid $white;
            border-radius: 100%;
            @include flex-center;
            position: absolute;
          }

          &.down {

            &::before {
              margin-left: -2px;
              transform: rotate(270deg);
            }

          }

        }

        button.delete {
          @include button-style("i", $red-01, $red-01, $buttonIcon: true, $buttonBackground: false);
          border: none;
        }

      }

    }

    button.add-element {
      @include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);
      max-width: max-content;
    }

  }

  .accordion-container {
    width: 100%;

    .accordion-item {
      width: 100%;
      display: flex;
      flex-flow: column;
      row-gap: 20px;

      .accordion-header {

        .title-fields-container {
          cursor: pointer;

          h2 {
            width: calc(100% - 40px);
            cursor: pointer;
          }

          .accordion-arrow {
            width: 20px;
            height: 20px;
            background: $transparent;
            border: none;
            padding: 0;
            position: relative;
            margin-left: 20px;
            @include flex-center;
            pointer-events: none;

            &::before {
              content: "b";
              width: 7px;
              height: 7px;
              color: $white;
              font-size: 7px;
              font-family: $font-icon;
              position: absolute;
              pointer-events: none;
            }

            &.closed {
              transform: rotate(-90deg);
            }
          }

        }

      }

      .accordion-content-container {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        row-gap: 20px;
      }

    }

  }

  .field-container {
    @include flex-start;
    flex-flow: column;
    padding: 0 10px;
    overflow: visible;

  /* Crea in automatico le larghezze da 0 a 100 ogni 1 */
    @for $i from 0 through 100 {
      $width: $i;

      &.ratio-#{$width} {
        width: #{$width + "%"};
      }

    }

    &.ratio-66 {
      width: 66.666%;
    }

    &.ratio-33 {
      width: 33.333%;
    }

    &.ratio-0,
    &.radio-null,
    &.radio-undefined {
      width: 0;
      display: none;
    }

    &.ratio-auto {
      width: auto;

      addiction-date-picker {
        min-width: 420px;
      }

    }

    &.bigger {

      > .text {
        font-size: 20px;
      }

      addiction-input::ng-deep {

        .form-field {

          .form-input-container {

            &.number {

              &::before {
                right: 15px;
                top: 15px;
              }

              &::after {
                right: 15px;
                bottom: 15px;
              }

            }

            input {
              height: 60px;
              padding: 5px 15px;
              @include font-style(20px, $font-regular, $white, 1px, 100%);
              cursor: text;
            }

          }

        }

      }

      addiction-select::ng-deep {

        .select-container {

          select {
            height: 60px;
            padding: 5px 15px;
            @include font-style(20px, $font-regular, $white, 1px, 100%);
            cursor: text;
          }

        }

        mat-form-field {
          height: 60px;

          .mat-mdc-text-field-wrapper {

            .mat-mdc-form-field-flex {

              .mat-mdc-form-field-infix {

                .mat-mdc-select {
                  padding: 5px 15px;

                  .mat-mdc-select-trigger {

                    .mat-mdc-select-value {

                      .mat-mdc-select-min-line {
                        @include font-style(20px, $font-regular, $white, 1px, 100%);
                        cursor: pointer;
                      }

                    }

                  }

                }

              }

            }

          }

        }

      }

      .info-container {

        .value {
          font-size: 20px;
        }

      }

    }

    &.entity-version {
      display: flex;
      flex-flow: row;
      align-items: center;

      label {
        width: 50%;
        margin: 0;
      }

      .entity-version-container {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 5px;

        .version {
          @include font-style(12px, $font-regular, $white, 0, 100%);
          text-transform: uppercase;
        }

        button {

          &.versioning {
            @include button-style('w', $brand-color-04, $white, $buttonIcon: true, $buttonBackground: false);
            height: 20px;
            border: none;
          }

          &.checked {
            @include button-style('c', $brand-color-01, $ok-color, $buttonIcon: true, $buttonBackground: false);
            height: 20px;
            border: none;
          }

          &.refused {
            @include button-style('d', $brand-color-01, $ko-color, $buttonIcon: true, $buttonBackground: false);
            height: 20px;
            border: none;
          }

        }

      }

    }

    label,
    .label {
      width: 100%;
      @include font-style(10px, $font-medium, transparentize($white, 0.5), 2.5px, 100%);
      text-transform: uppercase;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      @include tooltip($white, 13px);
    }

    .required {
      width: auto;
      @include font-style(10px, $font-medium, transparentize($white, 0.5), 2.5px, 100%);
      text-transform: uppercase;
    }

    .title {
      display: flex;
      align-items: center;
      @include tooltip($white, 15px);
    }

    > .text {
      width: 100%;
      @include font-style(14px, $font-regular, $white, 1px, 180%);
    }

    .skeleton {
      @include skeleton-loading;

      &.label {
        width: 30%;
        height: 10px;
        margin-bottom: 10px;
        border-radius: 3px;
      }

      &.input {
        width: 100%;
        height: 36px;
        border-radius: 5px;
      }

    }

    addiction-input { /* stile dentro addiction-components.scss */ }

    addiction-select { /* stile dentro addiction-components.scss */ }

    addiction-email { /* stile dentro addiction-components.scss */ }

    mat-slide-toggle {
      appearance: none;

      &[aria-readonly="true"],
      &[aria-disabled="true"] {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
      }

      &::ng-deep {

        .mdc-form-field {
          appearance: none;

          button.mdc-switch {
            --mdc-switch-handle-height: 26px;
            --mdc-switch-handle-shape: 13px;
            --mdc-switch-handle-width: 26px;
            --mdc-switch-track-height: 36px;
            --mdc-switch-track-shape: 18px;
            --mdc-switch-track-width: 70px;

            --mdc-switch-disabled-handle-opacity: 1;
            --mdc-switch-disabled-selected-icon-opacity: 1;
            --mdc-switch-disabled-track-opacity: 1;
            --mdc-switch-disabled-unselected-icon-opacity: 1;
            --mdc-switch-selected-focus-state-layer-opacity: 1;
            --mdc-switch-selected-hover-state-layer-opacity: 1;
            --mdc-switch-selected-pressed-state-layer-opacity: 1;
            --mdc-switch-unselected-focus-state-layer-opacity: 1;
            --mdc-switch-unselected-hover-state-layer-opacity: 1;
            --mdc-switch-unselected-pressed-state-layer-opacity: 1;

            &.mdc-switch--checked {

              .mdc-switch__track { //sfondo

              }

              .mdc-switch__handle-track {
                margin: 0 -5px;

                .mdc-switch__handle {

                  &::after {
                    background: $white !important;
                  }

                  .mdc-switch__icons {
                    @include flex-center;

                    &::before {
                      content: "c";
                      font-size: 9px;
                      margin-top: 2px;
                      color: $brand-color-04;
                    }

                  }

                }

              }

            }

            .mdc-switch__track { //sfondo

              &::before {
                background: transparentize($white, 0.9) !important;
              }

              &::after {
                background: $brand-color-04 !important;
              }

            }

            .mdc-switch__handle-track {
              margin: 0 5px;
              transition: $standard-transition;

              .mdc-switch__handle {
                --mat-switch-with-icon-handle-size: 26px;

                &::before {
                  display: none;
                }

                &::after {
                  background: $brand-color-04 !important;
                  transition: $standard-transition;
                }

                .mdc-switch__shadow,
                .mdc-switch__ripple {
                  display: none;
                }

                .mdc-switch__icons {
                  @include flex-center;

                  &::before {
                    content: "d";
                    width: 10px;
                    height: 10px;
                    font-size: 7px;
                    color: $white;
                    font-family: $font-icon;
                    @include flex-center;
                    position: absolute;
                    pointer-events: none;
                    transition: $standard-transition;
                  }

                  svg {
                    display: none;
                  }

                }

              }

            }

          }

          label {
            @include font-style(14px, $font-regular, $white, 0, 130%);
            margin-left: 10px;
            @include tooltip($white, 13px);
          }

        }

      }

    }

    .info-container { /* campo con solo informazioni es. "tipo" "formato" "data" */
      width: 100%;

      .label {
        @include font-style(10px, $font-medium, transparentize($white, 0.5), 2.5px, 100%);
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      .value {
        @include font-style(14px, $font-regular, $white, 0.5px, 160%);
        word-break: break-all;
      }

    }

    .answers-container {
      width: 100%;
      display: flex;
      flex-flow: column;

      label {
        margin: 20px 0;
      }

      .single-answer {
        width: 100%;
        display: flex;
        flex-flow: column;
        row-gap: 20px;

        .accordion-header {
          min-height: 56px;
          border-bottom: 1px solid transparentize($brand-color-08, 0.85);
          display: flex;
          align-items: center;
          cursor: pointer;

          &.disabled {
            cursor: not-allowed;

            .right-container {
              .accordion-arrow {
                opacity: .5;
              }
            }

          }

          .left-container {
            flex: 1;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .number {
              width: 30px;
              height: 30px;
              border-radius: 5px;
              background: $brand-color-12;
              margin-right: 20px;
              display: flex;
              flex-flow: column;
              @include flex-center;
              transition: $standard-transition;
              @include font-style(10px, $font-medium, $white, 1.5px, 100%);
              cursor: pointer;
              text-align: center;
            }

            .title {
              @include font-style(14px, $font-medium, $white, 0, 130%);
              cursor: pointer;
            }

          }

          .right-container {
            width: 200px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .count {
              @include font-style(12px, $font-regular, $white, 0, 130%);
              cursor: pointer;
            }

            .accordion-arrow {
              width: 30px;
              height: 30px;
              border-radius: 5px;
              background: $brand-color-04;
              margin-left: 20px;
              @include flex-center;
              pointer-events: none;
              position: relative;
              transition: $standard-transition;

              &::before {
                content: "b";
                width: 7px;
                height: 7px;
                color: $white;
                font-size: 7px;
                font-family: $font-icon;
                position: absolute;
                pointer-events: none;
                transform: rotate(0deg);
                transition: $standard-transition;
              }

              &.closed {
                background: $brand-color-12;

                &::before {
                  transform: rotate(90deg);
                }

              }

            }

          }

        }

        .accordion-content-container {
          width: 100%;
          display: flex;
          flex-flow: row wrap;
          row-gap: 20px;
          margin: 20px 0;
        }

      }

    }

    .feature-chips-selector-container {
      width: 100%;

      datalean-feature-chips-selector { /* stile dentro chip-selector.component.scss */ }

    }

    .asset-selector-container { /* stile dentro asset-selector.component.scss */ }

    .single-thumbnail-container {
      width: 100%;
      display: flex;
      flex-flow: row wrap;

      .dimensions,
      .bytes {
        flex: 1;
        @include font-style(14px, $font-regular, $white, 1px, 150%);
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .bytes {
        padding: 0 10px;
      }

      button.download {
        width: 20px;
        height: 20px;
        background: $transparent;
        box-shadow: none;
        border: none;
        padding: 0;
        margin: 0;
        position: relative;
        @include flex-center;
        cursor: pointer;
        transition: $standard-transition;

        &::before {
          content: "K";
          width: 16px;
          height: 16px;
          font-size: 16px;
          color: $white;
          font-family: $font-icon;
          position: absolute;
          cursor: pointer;
          transition: $standard-transition;
        }

        @media (hover: hover) and (pointer: fine) {

          &:hover {
            opacity: 0.5;
          }

        }

        &:disabled,
        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

      }

    }

    .buttons-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-flow: row;

      button {
        @include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);
        margin-left: 20px;
      }

    }

    .simple-container-container {
      width: 100%;
      display: flex;
      flex-flow: column;
      margin: 10px 0;
      padding-left: 17px; //commenti temporanei da lasciare: per Ruoli tolta riga
      border-left: 3px solid transparentize($brand-color-04, 0.2); //commenti temporanei da lasciare: per Ruoli tolta riga

      &.no-border {
        padding-left: 0;
        border-left: 0;
      }

      .container-title {
        width: 100%;
        @include font-style(20px, $font-light, $white, 0, 100%);
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-bottom: 20px; //commenti temporanei da lasciare: per Ruoli tolta riga e aggiunto sotto
        //min-height: 56px;
        //padding: 15px 10px;
        //border-radius: 5px;
        //background: $brand-color-12;
        //@include font-style(14px, $font-medium, $white, 0, 100%);

        .title {
          @include font-style(20px, $font-light, $white, 0, 100%); //commenti temporanei da lasciare: per Ruoli @include font-style(14px, $font-medium, $white, 0, 100%);
          cursor: pointer;
        }

        &.accordion {
          justify-content: space-between;

          button.accordion {
            @include button-style("a", $white, $white, $buttonIcon: true, $buttonBackground: false);
            border: none;

            &::before {
              font-size: 7px;
              @include flex-center;
              margin-left: 2px;
              transform: rotate(90deg);
            }

            &::after { //commenti temporanei da lasciare: per Ruoli questo da togliere
              content: "";
              width: 16px;
              height: 16px;
              border: 1px solid $white;
              border-radius: 100%;
              @include flex-center;
              position: absolute;
            }

            &.down {

              &::before {
                margin-left: -2px;
                transform: rotate(270deg);
              }

            }

          }

        }

      }

      .accordion-content {
        //commenti temporanei da lasciare: per Ruoli questo sotto da aggiungere
        //height: auto;
        //padding-top: 20px;
        //padding-bottom: 10px;
        //overflow: visible;

        &.collapsed {
          height: 0;
          overflow: hidden;
          //commenti temporanei da lasciare: per Ruoli questo sotto da aggiungere
          //padding-top: 0;
          //padding-bottom: 0;

          & > * {
            display: none;
          }

        }

        &.expanded {
          height: auto;
          overflow: visible;
          //commenti temporanei da lasciare: per Ruoli questo sotto da aggiungere
          //padding-top: 20px;
        }

      }

      datalean-container,
      .datalean-container {
        /* stile dentro container.component.scss */
        width: calc(100% + 20px);
        margin-left: -10px;

        .container {
          form,
          .form-group-container {
            .field-container {
              .simple-container-container { //secondo livello
                border-left: 3px solid transparentize($brand-color-04, 0.4);

                datalean-container {
                  .container {
                    form,
                    .form-group-container {
                      .field-container {
                        .simple-container-container { //terzo livello
                          border-left: 3px solid transparentize($brand-color-04, 0.6);

                          datalean-container {
                            .container {
                              form,
                              .form-group-container {
                                .field-container {
                                  .simple-container-container { //quarto livello
                                    border-left: 3px solid transparentize($brand-color-04, 0.8);

                                    datalean-container {
                                      .container {
                                        form,
                                        .form-group-container {
                                          .field-container {
                                            .simple-container-container { //quinto livello
                                              border-left: 3px solid transparentize($brand-color-04, 0.9);
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

      }

    }

    .external-file-selector {
      width: 100%;

      input[type="file"] {
        width: 100%;
        height: 36px;
        @include font-style(14px, $font-regular, $white, 0, 100%);
        cursor: pointer;
        padding: 0;
        background: transparentize($white, 0.95);
        border: none;
        margin: 0;
        border-radius: 10px 5px 5px 10px;
        position: relative;

        &::placeholder {
          @include font-style(14px, $font-regular, transparentize($white, 0.5), 0, 100%);
        }

        &::file-selector-button {
          @include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);
          width: 150px;
          padding: 0;
          margin-right: 10px;
          display: inline-block;
        }

      }

    }

    .search-list-field {
      width: 100%;
      display: flex;
      flex-flow: column;

      .search-container {
        display: flex;
        gap: 20px;

        addiction-search {
          flex: 1;
        }

        button.add {
          @include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);
        }

      }

      .list-container {
        width: calc(100% + 40px);
        margin-left: -20px;

        addiction-list { //con questo funziona ma non si può obbligare ad avere un buco se c'è altro sotto
          height: 250px !important;
        }

      }

    }

    .images-radio-container {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      row-gap: 20px;
      column-gap: 20px;

      &.columns-3 {
        .single-image-radio {
          width: calc(33.333% - 13.333px);
        }
      }

      &.columns-4 {
        .single-image-radio {
          width: calc(25% - 15px);
        }
      }

      .single-image-radio {
        width: calc(14.285% - 17.142px);
        max-height: 250px;
        height: auto;
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        align-items: center;

        label {
          width: 100%;
          height: calc(100% - 28px);
          margin: 0;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: bottom;
            cursor: pointer;
          }

        }

        input {
          width: 18px;
          height: 18px;
          background: transparentize($white, 0.9);
          border-radius: 100%;
          cursor: pointer;
          border: 5px solid $transparent;
          margin: 10px 0 0 0;
          position: relative;
          appearance: none;

          &:checked {
            background: $white;
            border: 5px solid $brand-color-04;
          }

        }

        .delete {
          @include button-style('i', $red-01, $red-01, $buttonIcon: true, $buttonBackground: false);
          border: none;
        }

      }

    }

    .hotspot-selected-image {
      width: 200px;
      height: 200px;
      padding: 20px;
      border-radius: 5px;
      @include flex-center;
      position: relative;
      background: transparentize($white, 0.9);
      transition: $standard-transition;

      &.full {
        background: $transparent;
        border: 1px solid transparentize($brand-color-08, 0.7);
      }

      .buttons-container {
        width: 100%;
        height: 100%;
        background: $transparent;
        @include flex-center;
        flex-flow: row wrap;
        column-gap: 20px;
        position: absolute;

        button {

          &.add {
            @include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);
          }

          &.edit {
            @include button-style("n", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);
          }

          &.delete {
            @include button-style("i", $red-01, $white, $buttonIcon: true, $buttonBackground: true);
          }

        }

      }

      img {
        width: 100%;
        height: 100%;
      }

    }

  }

}
