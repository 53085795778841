@import 'src/standard-mixins.scss';

/* ADDICTION DATE PICKER */
addiction-date-picker {
  width: 100%;

  .date-picker-container {
    width: 100%;
    display: flex;
    column-gap: 20px;

    mat-form-field {
      flex: 1;
      height: 36px;
      background: $transparent;
      border: none;
      border-radius: 0;
      padding: 0;
      position: relative;
      @include flex-center;
      transition: $standard-transition;

      &.mat-form-field-disabled {
        cursor: not-allowed;
        opacity: 0.5;

        .mat-mdc-text-field-wrapper {

          .mat-mdc-form-field-flex {

            .mat-mdc-form-field-icon-suffix {

              mat-datepicker-toggle {

                button {
                  pointer-events: none;
                }

              }

            }

          }

        }

      }

      .mat-mdc-text-field-wrapper {
        width: 100%;
        height: 100%;
        padding: 0;
        background: $transparent;

        .mat-mdc-form-field-focus-overlay {
          display: none;
        }

        .mat-mdc-form-field-flex {
          width: 100%;
          height: 100%;
          padding: 0;
          display: flex;
          align-items: center;
          background: $transparent;

          .mat-mdc-form-field-infix {
            width: 100%;
            height: 100%;
            min-height: 100%;
            padding: 0;
            margin: 0;
            border: 0;
            display: flex;
            flex-flow: column;
            border: none;

            //label {
            //  @include font-style(10px, $font-medium, transparentize($white, 0.5), 2.5px, 150%);
            //  text-transform: uppercase;
            //  margin-bottom: 5px;
            //}

            input {
              width: 100%;
              height: 100%;
              min-height: 100%;
              padding: 5px 36px 5px 10px;
              margin: 0;
              caret-color: $white;
              box-sizing: border-box;
              background: transparentize($white, 0.95);
              border-radius: 5px;
              @include font-style(14px, $font-regular, $white, 1px, 100%);
              cursor: inherit;
            }

            mat-select {
              .mat-mdc-select-trigger {
                .mat-mdc-select-value {
                  .mat-mdc-select-value-text {
                    .mat-mdc-select-min-line {
                      //testo
                    }
                  }
                }

                .mat-mdc-select-arrow-wrapper {
                  .mat-mdc-select-arrow {
                    svg {

                    }
                  }
                }
              }
            }

            mat-select {
              width: 100%;
              height: 100%;
              min-height: 100%;
              background: transparentize($white, 0.95);
              border-radius: 5px;
              padding: 5px 10px;

              &[aria-expanded='true'] {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
              }

              .mat-mdc-select-trigger {
                min-height: 100%;
                height: auto;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .mat-mdc-select-value {
                  padding: 3px 20px 0 0;

                  //.mat-mdc-select-value-text {}

                  .mat-mdc-select-min-line { //Elimina
                    @include font-style(14px, $font-regular, $white, 1px, 170%);
                    cursor: pointer;
                  }

                }

                .mat-mdc-select-arrow-wrapper {
                  transform: unset;
                  width: 10px;
                  position: absolute;
                  right: 0;

                  .mat-mdc-select-arrow {
                    width: 100%;
                    height: 100%;
                    margin: 0;
                    border: none;
                    @include flex-center;

                    &::before {
                      content: "b";
                      width: 10px;
                      height: 5px;
                      color: $white;
                      font-size: 7px;
                      font-family: $font-icon;
                      position: absolute;
                      cursor: pointer;
                      transition: $standard-transition;
                      @include flex-center;
                    }

                    svg {
                      display: none;
                    }

                  }

                }

              }

            }

            mat-datepicker {}

            .mat-form-field-label-wrapper {
              padding: 0;
            }

          }

          .mat-mdc-form-field-icon-suffix {
            padding: 0;
            position: absolute;
            right: 10px;

            mat-datepicker-toggle {

              button {
                width: 16px;
                height: 16px;
                padding: 0;
                @include flex-center;
                appearance: none;
                outline: none;
                border: none;
                background: $transparent;
                position: relative;
                transition: $standard-transition !important;

                @media (hover: hover) and (pointer: fine) {

                  &:hover {
                    opacity: 0.5;
                  }

                }

                &::before { //se non vogliamo che il button sia cliccabile, si sposta il &::before dentro mat-form-field {}
                  content: "o";
                  width: 100%;
                  height: 100%;
                  color: $white;
                  font-size: 16px;
                  font-family: $font-icon;
                  position: absolute;
                  pointer-events: none;
                  z-index: 1;
                }

                * {
                  display: none;
                }

              }

            }

          }

        }

        .mdc-line-ripple {
          display: none;
        }

        .mat-mdc-form-field-underline {
          display: none;
        }

      }

      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }

    }

  }

}

/* POPUP */
.cdk-overlay-container {

  .cdk-overlay-connected-position-bounding-box {

    .cdk-overlay-pane {

      /* POPUP DATE PICKER */
      &.mat-datepicker-popup {
        background: $secondary;
        transform: translateX(0) !important;

        * {
          color: $white;
          font-family: $font-default;
          fill: $white;
        }

        .mat-datepicker-content {
          background: $secondary;

          .mat-datepicker-content-container {

            mat-calendar {

              mat-calendar-header {

                .mat-calendar-header {

                  .mat-calendar-controls {
                    margin: 0;

                    > button {}

                  }

                }

              }

              .mat-calendar-content {

                mat-month-view,
                mat-year-view,
                mat-multi-year-view {

                  .mat-calendar-table {

                    .mat-calendar-table-header {
                      display: none;
                    }

                    .mat-calendar-body {

                      .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
                        border-color: transparentize($white, 0.9);
                      }

                      .mat-calendar-body-selected {
                        background-color: transparentize($white, 0.9);
                        color: $white;
                      }

                      .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
                      .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
                        background-color: transparentize($white, 0.5) !important;
                      }

                    }

                  }

                }

              }

            }

            .mat-datepicker-close-button {}

          }

        }

      }

    }

  }

}
