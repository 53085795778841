@import 'src/standard-mixins.scss';

/* ADDICTION AUTOCOMPLETE */
addiction-autocomplete,
datalean-autocomplete {
  width: 100%;
  display: flex;

  .form-field {
    width: 100%;
    display: flex;

    .form-input-container {
      width: 100%;
      display: flex;

      input {
        width: 100%;
        height: 36px;
        @include font-style(14px, $font-regular, $white, 0, 100%);
        cursor: text;
        padding: 5px 10px;
        background: transparentize($white, 0.95);
        border: none;
        border-radius: 5px;
        caret-color: $white;
        //flex: unset;
        //margin: 0;
        //box-sizing: border-box;
        //cursor: inherit;
      }

    }

  }

  mat-autocomplete {}

}

// STILE DA VERIFICARE
// QUESTE CLASSI ERANO STATE CREATE PER DATALEAN-AUTOCOMPLETE CHE E' STATO RIMOSSO
// .list-viewport {
//   height: 200px !important;
//   overflow-y: auto;
//   .cdk-virtual-scroll-content-wrapper {
//     padding: 0;
//   }
// }

// .option {
//   height: 50px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

/* POPUP */
.cdk-overlay-container {

  .cdk-overlay-connected-position-bounding-box {

    .cdk-overlay-pane {

      /* POPUP AUTOCOMPLETE */
      .mat-mdc-autocomplete-panel {
        border: none;
        border-radius: 0;
        margin-top: -4px;
        box-shadow: none;
        padding: 0;
        background: $secondary;
        @include scroll-bar;

        mat-optgroup {
          background: $secondary;
          transition: $standard-transition;

          .mat-mdc-optgroup-label {
            background: $secondary;

            .mdc-list-item__primary-text {
              @include font-style(12px, $font-medium, transparentize($white, 0.25), 1.2px, 130%);
            }

          }

        }

        mat-option {
          padding: 10px 16px;
          background: $secondary;
          transition: $standard-transition;

          @media (hover: hover) and (pointer: fine) {

            &:hover {
              opacity: 0.5;
            }

          }

          .mdc-list-item__primary-text {
            @include font-style(12px, $font-medium, transparentize($white, 0.25), 1.2px, 130%);
            cursor: pointer;
          }

          mat-pseudo-checkbox {
            display: none;
          }

          .mat-ripple {
            display: none;
          }

        }

      }

    }

  }

}