@import "variables.scss";

/* --- STANDARD MIXINS --- */

/* --- FLEX --- */
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

@mixin flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

/* --- FONTS --- */
//@include font-style(17px, $font-regular, $primary, 0, 200%, false);
@mixin font-style($size, $weight, $color, $letter, $line, $link: false) {
  font-family: $font-default;
  font-size: $size;
  font-weight: $weight;
  color: $color;
  line-height: $line;
  letter-spacing: $letter;

  @if $link {
    cursor: pointer;
    transition: $standard-transition;
  } @else {
    cursor: default;
  }

}

@mixin scroll-bar {
  //scrollbar-width: thin;
  //scrollbar-color: transparentize($white, 0.5) $transparent;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: transparentize($white, 0.5);
    box-shadow: none;
  }

  &::-webkit-scrollbar-track {
    background-color: $transparent;
  }

}

/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@mixin scroll-bar-dark {

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $brand-color-02;
    box-shadow: none;
  }

  &::-webkit-scrollbar-track {
    background-color: $transparent;
  }

}

@mixin skeleton-loading {
  background: linear-gradient(110deg, rgba(1, 40, 108, 0) 40%, rgba(1, 40, 108, 0.5) 60%, rgba(1, 40, 108, 0) 70%) $brand-color-05;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}

@keyframes loading {

  to {
    background-position-x: -20%;
  }

}

/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $brand-color-04, $brand-color-04, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $brand-color-04, $brand-color-04, $buttonIcon: false, $buttonBackground: false);
*/
@mixin button-style($contentOrHeight, $primaryColor, $secondaryColor, $buttonIcon: true, $buttonBackground: true, $buttonSize: '') {

  @if $buttonIcon {
    //E' un button con solo icona
    box-shadow: none;
    border-radius: 3px;
    padding: 0;
    margin: 0;
    position: relative;
    @include flex-center;
    cursor: pointer;
    text-transform: initial;
    transition: $standard-transition;

    &[aria-expanded='true'] {
      border-radius: 0;
    }

    @if $buttonBackground {
      width: 36px;
      height: 36px;
      background: $primaryColor;
      border: none;

      @media (hover: hover) and (pointer: fine) {

        &:hover {
          background: $secondaryColor;

          &::before {
            color: $primaryColor;
          }

        }

      }

    } @else {
      width: 34px;
      height: 34px;
      background: $transparent;
      border: 2px solid $primaryColor;

      @media (hover: hover) and (pointer: fine) {

        &:hover {
          opacity: 0.5;
        }

      }

    }

    &::before {
      content: $contentOrHeight;

      @if ($contentOrHeight == 'b' or $contentOrHeight == 'd') {
        //icone < e x devono essere più piccole
        width: 12px;
        height: 12px;
        font-size: 12px;
      } @else {
        width: 16px;
        height: 16px;
        font-size: 16px;
      }

      color: $secondaryColor;
      font-weight: $font-regular;
      letter-spacing: 0;
      line-height: 100%;
      font-family: $font-icon;
      position: absolute;
      cursor: pointer;
      transition: $standard-transition;
    }

  } @else {
    //E' un button con solo testo
    width: auto;
    padding: 5px 40px;
    @include flex-center;
    @include font-style(12px, $font-semibold, $secondaryColor, 2.5px, 130%);
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    transition: $standard-transition;

    span {
      width: 100%;
      @include font-style(12px, $font-semibold, $secondaryColor, 2.5px, 130%);
      cursor: pointer;
      text-align: center;
      @include flex-center;
      text-transform: uppercase;
      margin-top: 3px;
      transition: $standard-transition;
    }

    @if $buttonSize == "big" {
      padding: 10px 40px;
      font-size: 18px;

      span {
        font-size: 18px;
      }

    }

    @if $buttonBackground {
      min-height: $contentOrHeight;
      background: $primaryColor;
      border: none;
      border-radius: 10px;

      &[aria-expanded='true'] {
        border-radius: 0;
      }

      @media (hover: hover) and (pointer: fine) {

        &:hover {
          background: $secondaryColor;
          color: $primaryColor;

          span {
            color: $primaryColor;
          }

        }

      }

    } @else {
      min-height: calc($contentOrHeight - 2px);
      background: $transparent;
      border: 2px solid $primaryColor;
      border-radius: 3px;

      @media (hover: hover) and (pointer: fine) {

        &:hover {
          opacity: 0.5;
        }

      }

    }

  }

  &:disabled,
  &.disabled,
  &[disabled],
  &[disabled='true'] {
    opacity: 0.25;
    cursor: not-allowed;
    pointer-events: none;

    span {
      cursor: not-allowed;
      pointer-events: none;
    }

  }

}

@mixin no-results {
  width: 100%;
  height: auto;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;

  &.bigger {

    ng-lottie {
      width: 400px;
      height: 400px;
      margin-bottom: 0;
    }

  }

  ng-lottie {
    display: flex;
    width: 250px;
    height: 250px;
    object-fit: contain;

    &.arrow-top {
      transform: rotate(230deg);
    }

    &.arrow-bottom {
      transform: rotate(50deg);
    }

  }

  .title {
    width: 100%;
    margin-bottom: 10px;
    @include font-style(30px, $font-light, $white, 0, 130%);
    text-align: center;
  }

  .text {
    width: 100%;
    @include font-style(13px, $font-regular, transparentize($white, 0.35), 0, 150%);
    text-align: center;

    span {
      color: $brand-color-01;
    }

  }

}

@mixin tooltip($color, $size) {
  display: flex;
  align-items: center;

  .tooltip {
    width: 15px;
    height: 15px;
    @include flex-center;
    margin-left: 10px;
    position: relative;
    cursor: pointer;

    &::before {
      content: "F";
      font-family: $font-icon;
      font-size: $size;
      color: $color;
      position: absolute;
      pointer-events: none;
    }

  }

}
