@import "src/mixins.scss"; //ok
@import "src/standard-mixins.scss";

addiction-dam-header {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  background: $brand-color-13;
  border-bottom: 1px solid transparentize($brand-color-08, 0.7);
  width: 100%;
  height: auto;
  overflow: hidden;

  .header-container {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex: 1;

      button {

        &.back {
          @include button-style("a", $white, $white, $buttonIcon: true, $buttonBackground: false);
          margin-right: 20px;
        }

        &.menu-button {
          @include button-style("w", $white, $white, $buttonIcon: true, $buttonBackground: false);
          margin-right: 20px;
        }

        &.navigation-button {
          @include button-style("s", $white, $white, $buttonIcon: true, $buttonBackground: false);
          margin-right: 20px;
        }

        &.filters-button {
          @include button-style("y", $white, $white, $buttonIcon: true, $buttonBackground: false);
          margin-right: 20px;
        }

        &.active {
          border: 2px solid $brand-color-14;

          @media (hover: hover) and (pointer: fine) {

            &:hover {
              opacity: 1;
            }

          }

          &::before {
            color: $brand-color-14;
          }

        }

      }

      form.search-form {
        display: inline-block;
        width: 200px;
        padding: 0 5px;
        position: relative;
        @include flex-center;

        datalean-input {}

        .reset-search-button {
          width: 10px;
          height: 10px;
          position: absolute;
          right: 15px;
          @include flex-center;
          cursor: pointer;

          &::before {
            content: 'd';
            width: 10px;
            height: 10px;
            font-size: 10px;
            color: $white;
            font-family: $font-icon;
            position: absolute;
            cursor: pointer;
          }

        }

      }

    }

    .right-container {
      width: 100%;
      flex: 3;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row;
      background: $transparent;

      /* DIMENSIONE - uguale dentro collection-detail.component.scss */
      .size-container {
        display: flex;
        align-items: center;
        @include font-style(10px, $font-regular, $white, 2.5px, 100%);
        text-transform: uppercase;
        margin-left: 20px;

        label {
          @include font-style(10px, $font-regular, transparentize($white, 0.5), 2.5px, 100%);
          text-transform: uppercase;
          margin-right: 10px;
          @include tooltip($white, 13px);
        }

        .mat-mdc-slider {
          @include mat-slider;
        }

      }

      /* VIEW GRID/LIST - uguale dentro collection-detail.component.scss */
      .view-mode-container {
        @include flex-center;

        .view-button {
          width: 20px;
          height: 36px;
          background: $transparent;
          padding: 0;
          border: none;
          border-radius: 0;
          position: relative;
          @include flex-center;
          cursor: pointer;
          transition: $standard-transition;
          margin-left: 20px;

          @media (hover: hover) and (pointer: fine) {

            &:hover {
              opacity: 0.5;
            }

          }

          &::before {
            content: "v";
            width: 16px;
            height: 16px;
            color: $white;
            font-size: 16px;
            font-family: $font-icon;
            position: absolute;
            cursor: pointer;
            transition: $standard-transition;
            @include flex-center;
            pointer-events: none;
          }

          &.active {

            &::before {
              color: $brand-color-14;
            }

            @media (hover: hover) and (pointer: fine) {

              &:hover {
                opacity: 1;
              }

            }

          }

          &.list-view {

            &::before {
              content: "A";
            }

          }

          &.grid-view {

            &::before {
              content: "z";
            }

          }

        }

      }

      /* ORDER - uguale dentro collection-detail.component.scss */
      .order-container {
        display: flex;
        align-items: center;

        .select-container {
          display: flex;
          align-items: center;
          margin-left: 20px;

          label {
            @include font-style(10px, $font-regular, transparentize($white, 0.5), 2.5px, 100%);
            text-transform: uppercase;
            margin-right: 10px;
            @include tooltip($white, 13px);
          }

          addiction-select {
            min-width: 200px;
          }

        }

      }

      /* COLLEZIONI */
      .collections-container {
        margin-left: 20px;

        .collections-button {
          width: auto;
          height: 36px;
          background: $transparent;
          padding: 5px 10px 5px 35px;
          border: 2px solid $white;
          border-radius: 3px;
          position: relative;
          @include flex-center;
          cursor: pointer;
          transition: $standard-transition;

          @media (hover: hover) and (pointer: fine) {

            &:hover {
              opacity: 0.5;
            }

          }

          &::before {
            content: "v";
            width: 14px;
            height: 14px;
            color: $white;
            font-size: 14px;
            font-family: $font-icon;
            position: absolute;
            left: 10px;
            cursor: pointer;
            transition: $standard-transition;
            @include flex-center;
            pointer-events: none;
          }

          .label {
            @include font-style(12px, $font-semibold, $white, 2.5px, 100%);
            text-transform: uppercase;
            cursor: pointer;
            @include tooltip($white, 15px);
          }

        }

      }

      /* BULK ACTIONS */
      //.bulk-actions-panel {
      //  height: 70px;
      //  margin-right: 20px;
      //
      //  .panel-container {
      //    display: inline-flex;
      //    width: 100%;
      //    justify-content: flex-start;
      //    align-items: baseline;
      //    position: absolute;
      //    left: 0;
      //
      //    .danger {
      //      width: 150px;
      //      border: 2px solid $red-01;
      //      background: $transparent;
      //      height: 36px;
      //      border-radius: 10px;
      //      margin-top: 20px;
      //      text-transform: uppercase;
      //      @include font-style(12px, $font-semibold, $red-01, 3px, 100%);
      //      cursor: pointer;
      //      transition: $standard-transition;
      //
      //      @media (hover: hover) and (pointer: fine) {
      //
      //        &:hover {
      //          opacity: 0.5;
      //        }
      //
      //      }
      //
      //    }
      //
      //  }
      //
      //}

      /* ELIMINA */
      .delete-container {
        display: flex;
        margin-left: 20px;

        button.delete {
          @include button-style("i", $red-01, $red-01, $buttonIcon: true, $buttonBackground: false);
        }

      }

      /* NUOVO */
      .create-new-container {
        display: flex;
        margin-left: 20px;

        button.add-new {
          @include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);
        }

      }

    }

  }

}
