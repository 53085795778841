@import "src/standard-mixins.scss";

/* POPUP CONFIRMATION MESSAGE */
addiction-confirmation-message,
datalean-message {

  .modal-input-container {
    min-width: 400px;
    max-width: 600px;
    height: auto;
    max-height: 800px;
    padding: 25px 30px;
    border: none;
    border-radius: 0;
    background: $brand-color-02;

    .header-container {
      height: 36px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .title {
        width: calc(100% - 56px);
        @include font-style(20px, $font-light, $white, 0, 100%);
        //word-break: break-all;
        @include tooltip($white, 15px);
      }

      .close-container {
        @include flex-center;

        button.close {
          @include button-style("d", $white, $white, $buttonIcon: true, $buttonBackground: false);
        }

      }

    }

    .body-container {
      height: calc(100% - 56px);
      @include flex-start;
      flex-flow: column;
      overflow-y: auto;
      @include scroll-bar;

      .text {
        width: 100%;
        @include font-style(14px, $font-regular, $white, 0, 150%);
        text-align: center;

        span {
          font-size: 18px;
          font-weight: $font-medium;
        }

      }

      .button-container {
        width: 100%;
        margin-top: 30px;
        margin-bottom: 0;
        @include flex-center;

        button {
          @include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);
          margin: 0 10px;
        }

      }

    }

  }

}
