@import "src/mixins.scss"; //ok
@import "src/standard-mixins.scss";

/* ADDICTION GRID OPTIONS */
addiction-grid-options {
  width: 20px;

  .option-container {
    width: 25px;
    height: 35px;
    @include button-mat-menu("H", $brand-color-03, $primary, 14px, $brand-color-03, $white);
  }

}
