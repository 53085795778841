@import 'src/standard-mixins.scss';

/* ADDICTION SELECT */
addiction-select {
  width: 100%;
  display: flex;

  &.disabled,
  &[ng-reflect-readonly="true"] {
    pointer-events: none;

    mat-form-field {
      cursor: not-allowed;
    }

    .select-container {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    }

  }

  label {
    width: 100%;
    display: flex;
    align-items: center;
    @include font-style(10px, $font-medium, transparentize($white, 0.5), 2.5px, 150%);
    text-transform: uppercase;
    margin-right: 10px;
    @include tooltip($white, 13px);
  }

  .select-container {
    flex: 1;
    position: relative;

    &::before {
      content: "b";
      width: 100%;
      height: 100%;
      color: $white;
      font-size: 7px;
      font-family: $font-icon;
      position: absolute;
      cursor: pointer;
      transition: $standard-transition;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      pointer-events: none;
      right: 10px;
    }

    select {
      width: 100%;
      min-width: 250px;
      height: 36px;
      @include font-style(14px, $font-regular, $white, 0, 100%);
      padding: 5px 30px 5px 10px;
      cursor: pointer;
      background: $brand-color-16;
      border: none;
      margin: 0;
      border-radius: 5px;
      outline: 0 !important;
      appearance: none;
    }

  }

  mat-form-field {
    flex: 1;
    height: 36px;
    background: $transparent;
    border: none;
    border-radius: 5px;
    padding: 0;
    position: relative;
    @include flex-center;
    transition: $standard-transition;

    .mat-mdc-text-field-wrapper {
      width: 100%;
      height: 100%;
      padding: 0;
      background: $transparent !important;

      .mat-mdc-form-field-focus-overlay {
        display: none;
      }

      .mat-mdc-form-field-flex {
        width: 100%;
        height: 100%;
        padding: 0;
        display: flex;
        align-items: center;
        background: $transparent;

        .mat-mdc-form-field-infix {
          width: 100%;
          height: 100%;
          min-height: 100%;
          background: $transparent;
          padding: 0;
          margin: 0;
          border: 0;
          display: flex;
          flex-flow: column;
          border: none;

          .mat-mdc-select {
            width: 100%;
            height: 100%;
            min-height: 100%;
            background: transparentize($white, 0.95);
            border-radius: 5px;
            padding: 5px 10px;

            &.mat-mdc-select-disabled,
            &[aria-readonly="true"],
            &[aria-disabled="true"] {
              opacity: 0.5;
              cursor: not-allowed;
              pointer-events: none;
            }

            &[aria-expanded='true'] {
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
            }

            .mat-mdc-select-trigger {
              min-height: 100%;
              height: auto;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .mat-mdc-select-value {
                padding: 3px 20px 0 0;

                //.mat-mdc-select-value-text {}

                .mat-mdc-select-min-line {
                  @include font-style(14px, $font-regular, $white, 1px, 170%);
                  cursor: pointer;
                }

              }

              .mat-mdc-select-arrow-wrapper {
                transform: unset;
                width: 10px;
                position: absolute;
                right: 0;

                .mat-mdc-select-arrow {
                  width: 100%;
                  height: 100%;
                  margin: 0;
                  border: none;
                  @include flex-center;

                  &::before {
                    content: "b";
                    width: 10px;
                    height: 5px;
                    color: $white;
                    font-size: 7px;
                    font-family: $font-icon;
                    position: absolute;
                    cursor: pointer;
                    transition: $standard-transition;
                    @include flex-center;
                  }

                  svg {
                    display: none;
                  }

                }

              }

            }

          }

        }

        .mat-mdc-form-field-icon-suffix {
          padding: 0;

          mat-datepicker-toggle {

            button {
              width: 16px;
              height: 16px;
              padding: 0;
              @include flex-center;
              appearance: none;
              outline: none;
              border: none;
              background: $transparent;
              position: relative;

              @media (hover: hover) and (pointer: fine) {

                &:hover {
                  opacity: 0.5;
                }

              }

              &::before { //se non vogliamo che il button sia cliccabile, si sposta il &::before dentro mat-form-field {}
                content: "o";
                width: 100%;
                height: 100%;
                color: $white;
                font-size: 16px;
                font-family: $font-icon;
                position: absolute;
                pointer-events: none;
                z-index: 1;
              }

              * {
                display: none;
              }

            }

          }

        }

      }

      .mdc-line-ripple {
        display: none;
      }

      .mat-mdc-form-field-underline {
        display: none;
      }

    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }

  }

}

/* DATALEAN-SELECT */
datalean-select {
  width: 100%;
  min-height: 39px;
  @include flex-center;

  /* NON MATERIAL STYLE */
  label {
    width: 100%;
    display: flex;
    @include font-style(10px, $font-medium, transparentize($white, 0.5), 2.5px, 150%);
    text-transform: uppercase;
    margin-right: 10px;
  }

  .select-container {
    width: 100%;
    position: relative;
    @include flex-center;

    //&::before {
    //  content: "b";
    //  width: 10px;
    //  height: 10px;
    //  font-family: $font-icon;
    //  font-size: 6px;
    //  color: $white;
    //  position: absolute;
    //  right: 10px;
    //  @include flex-center;
    //  transition: $standard-transition;
    //  pointer-events: none;
    //}

    select {
      width: 180px;
      height: 36px;
      appearance: none;
      outline: none;
      border: none !important;
      border-radius: 5px;
      background: transparentize($white, 0.95);
      padding: 5px 30px 5px 10px;
      @include font-style(14px, $font-regular, $white, 1px, 100%);
      cursor: pointer;
    }

  }

  /* MATERIAL STYLE */
  /* stile dentro select-field.component.scss */
  //mat-form-field {
  //  width: 100%;
  //  height: 100%;
  //
  //  ::ng-deep .mat-mdc-text-field-wrapper {
  //    height: 100%;
  //    padding: 0;
  //    background: $transparent;
  //
  //    .mat-mdc-form-field-flex {
  //      height: 100%;
  //      padding: 0;
  //
  //      .mat-mdc-form-field-infix {
  //        padding: 0;
  //        margin: 0;
  //        border: none;
  //        width: auto;
  //        height: 100%;
  //        display: flex;
  //        flex-flow: row;
  //        align-items: center;
  //
  //        label {
  //          position: relative;
  //          transform: unset;
  //          top: unset;
  //          left: unset;
  //          width: auto;
  //          display: flex;
  //          align-items: center;
  //          margin: 0 10px 0 0;
  //
  //          mat-label {
  //            @include font-style(10px, $font-medium, transparentize($white, 0.5), 2.5px, 150%);
  //            text-transform: uppercase;
  //          }
  //
  //        }
  //
  //        .mat-mdc-select {
  //          width: 180px;
  //          height: 40px;
  //          min-height: 39px;
  //          background: transparentize($white, 0.95);
  //          border-radius: 5px;
  //
  //          .mat-mdc-select-trigger {
  //            min-height: 39px;
  //            height: auto;
  //            display: flex;
  //            justify-content: flex-start;
  //            align-items: center;
  //
  //            .mat-mdc-select-value {
  //              padding: 5px 20px 5px 10px;
  //
  //              .mat-mdc-select-value-text {
  //
  //                .mat-mdc-select-min-line { //Elimina
  //                  @include font-style(14px, $font-regular, $white, 1px, 170%);
  //                  cursor: pointer;
  //                }
  //
  //              }
  //
  //            }
  //
  //            .mat-mdc-select-arrow-wrapper {
  //              transform: unset;
  //              width: 10px;
  //              position: absolute;
  //              right: 10px;
  //
  //              .mat-mdc-select-arrow {
  //                width: 100%;
  //                height: 100%;
  //                margin: 0;
  //                border: none;
  //
  //                &::before {
  //                  content: "b";
  //                  width: 100%;
  //                  height: 100%;
  //                  color: $white;
  //                  font-size: 10px;
  //                  font-family: $font-icon;
  //                  position: absolute;
  //                  cursor: pointer;
  //                  transition: $standard-transition;
  //                  @include flex-center;
  //                }
  //
  //                svg {
  //                  display: none;
  //                }
  //
  //              }
  //
  //            }
  //
  //          }
  //
  //        }
  //
  //      }
  //
  //    }
  //
  //    .mdc-line-ripple,
  //    .mat-mdc-form-field-underline {
  //      display: none;
  //    }
  //
  //  }
  //
  //  ::ng-deep .mat-mdc-form-field-subscript-wrapper {
  //    display: none;
  //  }
  //
  //}

}
