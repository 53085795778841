@import 'src/mixins.scss'; //ok
@import 'src/standard-mixins.scss';

addiction-checkbox {

  &.indeterminate {

  }

	.form-field {

		&.disabled {

			.checkbox-default {

				label {
					opacity: 0.5;
				}

			}

		}

		.checkbox-default {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;

			label {
				@include font-style(14px, $font-regular, $white, 0, 130%);
				margin-left: 10px;
        @include tooltip($white, 13px);
			}

			input {
				@include input-checkbox;
			}

		}

	}

}
