@import 'src/standard-mixins.scss';

/* ADDICTION SEARCH */
addiction-search {
  width: 300px;
  @include flex-center;
  position: relative;

  button.search {
    width: 16px;
    height: 16px;
    position: absolute;
    left: 10px;
    border: none;
    padding: 0;
    background: $transparent;
    @include flex-center;
    cursor: pointer;
    z-index: 1;

    @media (hover: hover) and (pointer: fine) {

      &:hover {
        opacity: 0.5;
      }

    }

    &::before {
      content: "h";
      width: 16px;
      height: 16px;
      font-size: 16px;
      color: $white;
      font-family: $font-icon;
      position: absolute;
      cursor: pointer;
    }

  }

  addiction-input {
    width: 100%;

    .form-field {

      .form-input-container {

        input {
          padding: 5px 30px 5px 40px;
        }

      }

    }

  }

  button.reset {
    width: 10px;
    height: 10px;
    position: absolute;
    right: 10px;
    border: none;
    padding: 0;
    background: $transparent;
    @include flex-center;
    cursor: pointer;
    z-index: 1;

    @media (hover: hover) and (pointer: fine) {

      &:hover {
        opacity: 0.5;
      }

    }

    &::before {
      content: "d";
      width: 10px;
      height: 10px;
      font-size: 10px;
      color: $white;
      font-family: $font-icon;
      position: absolute;
      cursor: pointer;
    }

  }

}
