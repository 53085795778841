@import "src/standard-mixins.scss";

/* ADDICTION BULK ACTION BAR */
addiction-bulk-action-bar {
  height: 70px;
  width: calc(100% - 40px);
  padding: 0;
  margin: 0 20px;
  background: $brand-color-02;
  border-top: 1px solid transparentize($brand-color-08, 0.7);
  position: absolute;
  bottom: -70px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* Se si uniforma addiction-bulk-action-bar con datalean-bulk-actions-selector,
  ci sarà da togliere da qua il border top, position absolute e bottom -70px (che vanno solo nel DAM) */

  .left-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    label {
      @include font-style(10px, $font-medium, transparentize($white, 0.5), 2.5px, 130%);
      text-transform: uppercase;
      margin-right: 10px;
      @include tooltip($white, 13px);
    }

    addiction-select { /* stile dentro addiction-components.scss */
      min-width: 200px;
    }

    .counter {
      margin-left: 20px;

      span {
        @include font-style(14px, $font-regular, $white, 1px, 100%, false);
      }

    }

  }

  .right-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .buttons-container {
      display: flex;

      button {
        width: 100%;
        height: 36px;
        padding: 5px 20px;
        background: $transparent;
        border: 2px solid $white;
        border-radius: 10px;
        @include font-style(12px, $font-semibold, $white, 1.2px, 100%);
        cursor: pointer;
        text-align: center;
        text-transform: uppercase;
        margin-left: 20px;

        @media (hover: hover) and (pointer: fine) {

          &:hover {
            opacity: 0.5;
          }

        }

        &.disabled,
        &[disabled],
        &[disabled="true"] {
          opacity: 0.25;
          cursor: not-allowed;
        }

        &.execute {
          border: 2px solid $white;
          color: $white;
        }

        &.cancel {
          border: 2px solid $red-01;
          color: $red-01;
        }

      }

    }

  }

}
