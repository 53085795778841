@import "src/standard-mixins.scss";

addiction-chip {
  display: inline-block;

  .chip {
    display: flex;
    align-items: center;
    background: $brand-color-02;
    padding: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    border-radius: 3px;
    cursor: pointer;
    transition: $standard-transition;

    @media (hover: hover) and (pointer: fine) {

      &:hover {
        opacity: 0.5;
      }

    }

    .label {
      @include font-style(12px, $font-regular, $white, 0.85px, 130%);
      cursor: pointer;
      @include tooltip($white, 15px);
    }

    button.remove {
      width: 10px;
      height: 100%;
      padding: 0;
      background-color: $transparent;
      border: none;
      @include flex-center;
      position: relative;
      margin: 0 0 0 10px;
      cursor: pointer;

      &::before {
        content: "d";
        width: 7px;
        height: 7px;
        color: $white;
        font-size: 7px;
        font-family: $font-icon;
        position: absolute;
        cursor: pointer;
        transition: $standard-transition;
        @include flex-center;
      }

    }

  }

}
