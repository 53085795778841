@import "src/standard-mixins.scss";

/* TODO - Rimuovere datalean e lasciare solo addiction */
datalean-input-dialog,
addiction-input-dialog {

  datalean-input {
    margin-bottom: 20px;
  }

  .button-container {
    @include flex-center;
    margin-top: 10px;

    button {
      padding: 10px 20px;
      background: $brand-color-04;
      border: none;
      border-radius: 5px;
      @include font-style(12px, $font-semibold, $white, 2.5px, 100%);
      text-transform: uppercase;
      cursor: pointer;
      transition: $standard-transition;

      @media (hover: hover) and (pointer: fine) {

        &:hover {
          background: $white;
          color: $brand-color-04;
        }

      }

    }

  }

}
