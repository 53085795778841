@import "src/mixins.scss"; //ok
@import 'src/standard-mixins.scss';

/* ADDICTION-TREE-BROWSER */
addiction-tree-browser,
datalean-tree-browser {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;

  &.with-search-bar {

    .top-bar {
      display: flex;
      padding-bottom: 10px;
      border-bottom: 1px solid transparentize($brand-color-08, 0.7);

      addiction-search {
        width: calc(100% - 76px);
        display: flex;

        datalean-input {

          .form-field {
            margin: 0;

            .form-input-container {
              width: 100%;
            }

          }

        }

      }

      .buttons {
        width: auto;
        padding: 0 20px;
        align-items: center;
        justify-content: flex-end;
        margin: 0;
      }

    }

  }

  .top-bar {
    width: 100%;

    .page-title {
      @include font-style(20px, $font-light, $white, 0, 150%);
      margin-bottom: 30px;
    }

    addiction-search {
      width: 100%;
    }

    .buttons {
      width: 100%;
      @include flex-start;
      padding-right: 20px;

      button.add {
        @include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);
      }

    }

  }

  .tree-browser {
    flex: 1;
    height: auto;
    max-height: calc(100% - 36px);

    .current-node {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px 20px;
      border-bottom: 1px solid transparentize($brand-color-08, 0.7);

      .cdk-drag-placeholder {
        /* Questa regola è importante, serve da evitare che si visualizzi il placeholder (che non sarebbe stilato) durante il drag sulla cartella corrente (in alto) */
        display: none !important;
      }

      &.cdk-drop-list-dragging {
        /* Stato di quando una cartella sta passando (in dragging) sul nodo in alto della cartella corrente, stilare di conseguenza */
        font-weight: bold;
        text-decoration: underline;
      }

      button.back {
        width: 10px;
        height: 10px;
        appearance: none;
        border: none;
        padding: 0;
        background: $transparent;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        cursor: pointer;
        margin-right: 10px;

        @media (hover: hover) and (pointer: fine) {

          &:hover {
            opacity: 0.5;
          }

        }

        &::before {
          content: "b";
          width: 10px;
          height: 10px;
          font-size: 7px;
          color: $white;
          font-family: $font-icon;
          position: absolute;
          transform: rotate(90deg);
        }

      }

      .current-node-title {
        flex: 1;
        overflow: hidden;
        @include font-style(12px, $font-medium, $white, 1.2px, 100%);
        cursor: pointer;
        text-overflow: ellipsis;
      }

    }

    .cdk-drop-list.children {
      //padding: 10px 0;
      height: calc(100% - 50px);
      overflow-y: auto;
      @include scroll-bar;

      .drag-placeholder {
        width: 100%;
        height: 2px;
        background: $brand-color-04;
      }

      .child {
        width: 100%;
        min-height: 50px;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        border-radius: 5px;
        margin: 0;
        padding: 0 20px;
        cursor: pointer;
        transition: all 0s $bezier;

        //&.cdk-drag:not(.cdk-drag-disabled) {
        //  padding-left: 30px;
        //  cursor: default;
        //}

        .single-child-container {
          width: auto;
          height: 100%;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          overflow: hidden;
          cursor: pointer;

          input {
            @include input-checkbox;
            margin-right: 10px;
          }

          .title {
            width: 100%;
            min-height: 50px;
            position: relative;
            overflow: hidden;
            background: $transparent;
            border: none;
            cursor: pointer;
            padding: 0;
            @include flex-center;

            @media (hover: hover) and (pointer: fine) {

              &:hover {

                .label {
                  opacity: 0.5;
                }

              }

            }

            .folder {
              display: none;
            }

            .label {
              width: 100%;
              display: flex;
              @include font-style(12px, $font-medium, $white, 1.2px, 130%);
              text-align: left;
              cursor: pointer;
            }

          }

        }

        .controls {
          width: auto;
          height: 100%;
          @include flex-center;

          button.menu {
            width: 25px;
            height: 35px;
            margin-left: 5px;
            @include button-mat-menu("H", $brand-color-03, $primary, 14px, $brand-color-03, $white);

            &.more-space {
              margin-right: 12px;
            }

          }

          button.navigate {
            width: 12px;
            height: 25px;
            @include flex-center;
            cursor: pointer;
            opacity: 1;
            pointer-events: all;
            background: none;
            padding: 0;
            outline: 0;
            border: none;
            position: relative;

            &::before {
              content: "a";
              width: 100%;
              height: 100%;
              font-size: 7px;
              font-family: $font-icon;
              position: absolute;
              cursor: pointer;
              transition: $standard-transition;
              @include flex-center;
              transform: rotate(180deg);
            }

          }

        }

      }

      .placeholder {
        @include font-style(12px, $font-medium, $white, 1.2px, 100%);
        padding: 10px;
      }

    }

  }

  .checked-nodes {
    display: none;
  }

}
