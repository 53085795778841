@import "src/standard-mixins.scss";

/* Stile di base dentro modal-header.component.scss */

datalean-modal-header {

  .modal-title {

    .left {

      .title {
        @include font-style(20px, $font-light, $white, 0, 100%);
        word-break: break-all;
      }

    }

    .right {

      button.select,
      button.save-btn {
        height: 36px;
        padding: 5px 40px;
        @include font-style(12px, $font-semibold, $white, 2.5px, 100%);
        cursor: pointer;
        text-transform: uppercase;
        border-radius: 10px;
        background: $brand-color-04;
        border: none;
        transition: $standard-transition;

        @media (hover: hover) and (pointer: fine) {

          &:hover {
            background: $white;
            color: $brand-color-04;
          }

        }

      }

      button.cancel-btn {
        display: none;
      }

      button.close-icon {
        @include button-style("d", $white, $white, $buttonIcon: true, $buttonBackground: false);
      }

    }

  }

}
