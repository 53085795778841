@import "src/standard-mixins.scss";

/* ADDICTION PASSWORD FORM */
addiction-password-form {
  width: 100%;

  form {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 20px;

    .form-field {
      padding: 0;
      width: auto;
      flex: 1;

      label {
        width: 100%;
        display: flex;
        @include font-style(10px, $font-medium, transparentize($white, 0.5), 2.5px, 100%);
        text-transform: uppercase;
        margin-bottom: 10px;
        @include tooltip($white, 13px);
      }

      .input-container {
        width: 100%;
        position: relative;

        input {
          width: 100%;
          height: 36px;
          @include font-style(14px, $font-regular, $white, 0, 100%);
          padding: 5px 40px 5px 10px;
          cursor: text;
          background: transparentize($white, 0.95);
          border: none;
          margin: 0;
          border-radius: 5px;

          &::placeholder {
            @include font-style(14px, $font-regular, transparentize($white, 0.5), 0, 100%);
          }

          &:read-only {
            cursor: not-allowed;
          }

        }

        .icon {
          width: 40px;
          height: 36px;
          position: absolute;
          bottom: 0;
          right: 0;
          @include flex-center;
          cursor: pointer;

          @media (hover: hover) and (pointer: fine) {

            &:hover {
              opacity: 0.5;
            }

          }

          &::before {
            content: "";
            width: 20px;
            height: 20px;
            font-size: 20px;
            font-family: $font-icon;
            color: $white;
            position: absolute;
            pointer-events: none;
          }

          &.show {
            &::before {
              content: "O";
            }
          }

          &.hide {
            &::before {
              content: "P";
            }
          }

        }

      }

      .invalid-feedback {
        width: 100%;
        padding: 5px 10px;
        @include font-style(12px, $font-regular, $red-01, 0, 150%);

        li {
          list-style: none;
          @include font-style(12px, $font-regular, $red-01, 0, 150%);

          span {
            @include font-style(12px, $font-regular, $red-01, 0, 150%);
          }

        }

      }

    }

  }

}
