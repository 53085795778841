@import "src/mixins.scss"; //ok
@import 'src/standard-mixins.scss';

/* ADDICTION-SIMPLE-REGISTRATION */
addiction-simple-registration {

  .registration-container {

    .form-container {

      form {

        > div {
          margin-bottom: 20px;
        }

        .input-container {
          display: flex;
          flex-flow: column;

          label {
            width: 100%;
            @include font-style(10px, $font-medium, transparentize($white, 0.5), 1px, 100%);
            text-transform: uppercase;
            margin-bottom: 5px;
            @include tooltip($white, 13px);
          }

          input {
            width: 100%;
            height: 36px;
            background: transparentize($white, 0.9);
            border-radius: 0;
            border: none;
            appearance: none;
            outline: none;
            caret-color: $white;
            @include font-style(14px, $font-regular, $white, 0, 100%);
            padding: 5px 10px;
            cursor: inherit;
          }

        }

        .change-password-error {
          width: 100%;
          padding: 5px 10px;
          border-radius: 0;
          background: $ko-color;
          border: none;
          @include font-style(12px, $font-regular, $white, 0, 150%);
          text-align: center;
        }

        .checkbox-container {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin: 20px 0;

          mat-checkbox {
            @include mat-checkbox-style(transparentize($white, 0.9), $primary, none);
            margin-right: 20px;
          }

          .privacy-text {
            @include font-style(14px, $font-regular, $white, 0, 130%);

            .link {
              color: $primary;

              u {
                text-decoration: none;
              }

            }

          }

        }

        .buttons-container {
          width: 100%;
          margin-bottom: 0;
          @include flex-center;

          button {
            @include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);
            width: 100%;
          }

        }

      }

    }

    .registration-result-page {
      @include flex-center;
      flex-flow: column;

      .results-container {
        @include flex-center;
        flex-flow: column;
        margin-bottom: 50px;

        ng-lottie {
          display: flex;
          width: 250px;
          height: 250px;
          margin-bottom: 30px;
          object-fit: contain;
        }

        .title {
          width: 100%;
          margin-bottom: 10px;
          @include font-style(24px, $font-medium, $primary, 0, 150%);
          text-align: center;
        }

        .text {
          width: 100%;
          @include font-style(14px, $font-regular, $white, 0, 150%);
          text-align: center;

          span {
            color: $primary;
          }

        }

      }

      .result-button-container {
        width: 100%;
        margin-bottom: 0;
        @include flex-center;

        button {
          @include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);
          width: 100%;
        }

      }

    }


  }

}
