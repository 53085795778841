@import "src/standard-mixins.scss";

/* TODO - Rimuovere datalean e lasciare solo addiction + fare stile */
datalean-list,
addiction-simple-list {

  .page-title {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    padding: 0 0 10px 0;

    .title {
      @include font-style(10px, $font-medium, transparentize($white, 0.5), 2.5px, 150%);
      text-transform: uppercase;
    }

  }

  /* TODO - Togliere tutto il commento quando non ci sarà più list.component.scss e il componente sarà in addiction-components */
  //.datalean-list-container {
  //
  //  mat-list {
  //    padding: 0;
  //
  //    mat-list-item::ng-deep {
  //      width: 100%;
  //      border: none;
  //      padding: 0;
  //      margin-bottom: 20px;
  //      border-radius: 0;
  //      height: auto;
  //
  //      .mdc-list-item__content {
  //
  //        .mdc-list-item__primary-text {
  //
  //          .list-item {
  //            width: 100%;
  //            display: flex;
  //            border: 1px solid transparentize($brand-color-08, 0.8);
  //            overflow: hidden;
  //            padding: 0;
  //            border-radius: 5px;
  //
  //            .left-container {
  //              width: auto;
  //              background: $brand-color-12;
  //              border-radius: 5px;
  //              padding: 10px;
  //              display: flex;
  //              align-items: center;
  //
  //              .number {
  //                @include font-style(10px, $font-medium, $white, 2.5px, 100%);
  //              }
  //
  //            }
  //
  //            .details-container {
  //              width: auto;
  //              display: flex;
  //              flex: 1;
  //              padding: 20px 10px;
  //
  //              .single-detail {
  //                display: flex;
  //                flex-flow: column;
  //                flex: 1;
  //                padding: 0 10px;
  //
  //                .name {
  //                  @include font-style(10px, $font-medium, transparentize($white, 0.5), 2.5px, 100%);
  //                  margin-bottom: 10px;
  //                  text-transform: uppercase;
  //                }
  //
  //                .value {
  //                  @include font-style(14px, $font-regular, $white, 1px, 130%);
  //                  white-space: normal;
  //                }
  //
  //              }
  //
  //            }
  //
  //            .right-container {
  //              width: auto;
  //              background: $brand-color-12;
  //              border-radius: 5px;
  //              padding: 10px;
  //              display: flex;
  //              align-items: center;
  //
  //              .button-delete {
  //                width: 20px;
  //                height: 20px;
  //                position: relative;
  //                @include flex-center;
  //                padding: 0;
  //                margin: 0;
  //                border: none;
  //                background: $transparent;
  //                box-shadow: none;
  //
  //                @media (hover: hover) and (pointer: fine) {
  //
  //                  &:hover {
  //                    opacity: 0.5;
  //                  }
  //
  //                }
  //
  //                &::before {
  //                  content: "i";
  //                  width: 16px;
  //                  height: 16px;
  //                  font-size: 16px;
  //                  color: $red-01;
  //                  font-family: $font-icon;
  //                  position: absolute;
  //                  cursor: pointer;
  //                  transition: $standard-transition;
  //                  @include flex-center;
  //                }
  //
  //              }
  //
  //            }
  //
  //          }
  //
  //        }
  //
  //      }
  //
  //    }
  //
  //  }
  //
  //}

}
